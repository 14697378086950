import { graphql } from "gatsby"
import * as React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { repositoryConfigs } from "../utils/prismicPreviews"
import PageRemoveHeaderFooter from "./page-remove-header-footer"
import PageDefault from "./page-default"
import {TemplateContext} from "../layout"

const Page = (page) => {
  let template;
  switch (page?.pageContext?.template) {
    case "page-remove-header-footer":
      template = <PageRemoveHeaderFooter {...page} />
      break
    default:
      template = <PageDefault {...page} />
      break
  }
  return (<TemplateContext.Provider value={page?.pageContext?.template}>{template}</TemplateContext.Provider>)
}

export const query = graphql`
    query singlePage($id: String){
        prismicPage(id: {eq: $id}) {
            __typename
            _previewable
            data {
                slug_prefix
                meta_title
                meta_description
                title
                show_title_and_intro_text
                intro_text{
                    html
                }
                disclaimer_text {
                    html
                }
                body{
                    ...Hero
                    ...Text
                    ...ImageWithText
                    ...Video
                    ...Logos
                    ...Image
                    ...Testimonials
                    ...HubspotForm
                    ...Heading
                    ...Callout
                    ...Stats
                    ...RoiCalculator
                    ...Faqs
                    ...ContactInfo
                    ...MaxLoan
                    ...CalculatorWithText
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

Page.query = query

export default withPrismicPreview(Page, repositoryConfigs)
